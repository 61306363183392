import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Slider from "./js/Slider";
import lazySizes from "lazysizes";
import Menu from "./js/Menu";
import Headroom from "headroom.js";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./app.scss";

// barba.init({
//   // debug: true,
//   // logLevel: "error",
//   // timeout: 5000,
//   transitions: [
//     {
//       name: "opacity-transition",
//       leave(data) {
//         return gsap.to(data.current.container, {
//           opacity: 0,
//           duration: 0.2,
//         });
//       },
//       enter(data) {
//         return gsap.from(data.next.container, {
//           opacity: 0,
//           duration: 0.2,
//         });
//       },
//     },
//   ],
// });

gsap.registerPlugin(ScrollTrigger);

// barba.hooks.beforeEnter((data) => {});

// barba.hooks.after((data) => {
//   document.body.classList.remove("overflow-hidden");
//   window.scroll(0, 0);
//   runActions();
// });

runActions();

function runActions() {
  console.log("runActions");

  // action("Slider", Slider);
  document
    .querySelectorAll('[data-action="Slider"]')
    .forEach((el) => Slider(el));

  Menu(document.querySelectorAll(".menu-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  const modelBtn = document.querySelectorAll(".model-btn");
  const modelClose = document.querySelectorAll(".model-close");

  if (modelBtn) {
    modelBtn.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        // menu?.classList?.toggle("hidden");
        document.body.classList.add("overflow-hidden");
        const modelId = btn.dataset.target;
        const model = document.getElementById(modelId);
        model.classList.toggle("hidden");
      })
    );
  }

  if (modelClose) {
    modelClose.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        console.log(e.target.closest(".model"));
        e.target.closest(".model").classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      })
    );
  }
}
