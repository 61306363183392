import { gsap } from "gsap";

export default function Menu(els) {
  const menu = document.querySelector(".mob-menu");
  let isMenuOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      console.log("test");
      if (!isMenuOpen) {
        isMenuOpen = true;
        menu.classList.remove("hidden");
        gsap.fromTo(menu, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isMenuOpen = false;
        gsap.fromTo(
          menu,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => menu.classList.add("hidden"),
          }
        );
      }
    })
  );
}
